<template>
  <div>
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Inscriptions récentes
    </h2>
    <div v-if="users" class="w-full mb-8 overflow-hidden rounded-lg shadow-xs">
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Utilisateur</th>
              <th class="px-4 py-3">E-mail</th>
              <th class="px-4 py-3">Validation</th>
              <th class="px-4 py-3">Suspendu par un admin</th>
              <th class="px-4 py-3">Via</th>
              <th class="px-4 py-3">Activé</th>
              <th class="px-4 py-3">Inscrit depuis</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(user, index) in users"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <router-link
                :to="{ name: 'Account', params: { id: user.id, user: user } }"
                class="px-4 py-3 td-router-link"
              >
                <div class="flex items-center text-sm">
                  <!-- Avatar with inset shadow -->
                  <div
                    class="relative hidden w-8 h-8 mr-3 rounded-full md:block"
                  >
                    <img
                      class="object-cover w-full h-full rounded-full"
                      :src="getProfilePicture(user.profile_picture)"
                      alt=""
                      loading="lazy"
                    />
                    <div
                      class="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    ></div>
                  </div>
                  <div>
                    <p class="font-semibold">
                      {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="text-xs text-gray-600 dark:text-gray-400">
                      {{ user.username }}
                    </p>
                  </div>
                </div>
              </router-link>
              <td class="px-4 py-3 text-sm">
                {{ user.email.current_email }}
              </td>
              <td class="px-4 py-3 text-xs">
                <span
                  v-if="user.admin_validated === 1"
                  class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                >
                  Validé
                </span>
                <span
                  v-else-if="user.admin_validated === 0"
                  class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:text-white dark:bg-orange-600"
                >
                  En attente
                </span>
                <span
                  v-else-if="user.admin_validated === 2"
                  class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
                >
                  Refusé
                </span>
              </td>
              <td class="px-4 py-3 text-xs">
                <span
                  v-if="user.admin_suspended"
                  class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
                >
                  Oui
                </span>
                <span
                  v-else
                  class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                >
                  Non
                </span>
              </td>
              <td class="px-4 py-3 text-xs">
                <span
                  v-if="user.sign_up_from === 1"
                  class="px-2 py-1 font-semibold leading-tight text-gray-700 bg-gray-100 rounded-full dark:text-gray-100 dark:bg-gray-700"
                >
                  Appli
                </span>
                <span
                  v-else-if="user.sign_up_from === 2"
                  class="px-2 py-1 font-semibold leading-tight text-gray-700 bg-gray-100 rounded-full dark:text-gray-100 dark:bg-gray-700"
                >
                  Admin
                </span>
                <span
                  v-else
                  class="px-2 py-1 font-semibold leading-tight text-gray-700 bg-gray-100 rounded-full dark:text-gray-100 dark:bg-gray-700"
                >
                  Inconnu
                </span>
              </td>
              <td class="px-4 py-3 text-xs">
                <span
                  v-if="user.password.account_activation_token"
                  class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-700"
                >
                  Non
                </span>
                <span
                  v-else
                  class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                >
                  Oui
                </span>
              </td>
              <td class="px-4 py-3 text-sm">
                {{ dateTransform(user.created_at) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800"
      >
        <span class="col-span-2"></span>
        <label id="filter">
          <input
            v-model="onlyNotValidatedFilter"
            type="checkbox"
            @input="changeOnlyNotValidatedFilter"
          />
          <span>Uniquement les comptes en attente de validation</span>
        </label>
        <!-- Pagination -->
        <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Previous"
                  @click="changePagination(true)"
                >
                  <svg
                    aria-hidden="true"
                    class="w-4 h-4 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
              <li class="px-3 py-1 rounded-md">
                {{ currentPage + 1 }}
              </li>
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Next"
                  @click="changePagination(false)"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>
    <!-- TEMPORARY DISABLED
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Créer un nouveau utilisateur
    </h2>
    <div
      v-if="categories"
      class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800"
    >
      <label class="block text-sm">
        <span class="text-gray-700 dark:text-gray-400">Prénom</span>
        <input
          v-model="newUserForm.first_name"
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Jane"
        />
      </label>

      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Nom de famille</span>
        <input
          v-model="newUserForm.last_name"
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Doe"
        />
      </label>

      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Nom utilisateur</span>
        <input
          v-model="newUserForm.username"
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="jane.doe"
        />
      </label>

      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">E-mail</span>
        <input
          v-model="newUserForm.email"
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="jane.doe@gmail.com"
          type="email"
          id="email"
          name="email"
        />
      </label>

      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Date de naissance</span>
        <input
          v-model="newUserForm.birth_date"
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          type="date"
          id="date"
          name="date"
        />
      </label>

      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">
          Catégorie primaire
        </span>
        <select
          class="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
          v-model="newUserForm.primary_category_identifier"
        >
          <option
            v-for="(cat, index) in directoryTypes"
            :key="index"
            :value="cat.identifier"
            >{{ cat.fr_content }}</option
          >
        </select>
      </label>

      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Note (optionnel)</span>
        <textarea
          class="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-textarea focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
          rows="3"
          placeholder="Note à propos de l'utilisateur."
        ></textarea>
      </label>

      <button
        class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
        @click="postNewUser"
      >
        Créer
      </button>
      <h5 class="mt-2 text-sm font-semibold text-gray-700 dark:text-gray-200">
        L'utilisateur recevera un e-mail afin de créer un mot de passe, ainsi,
        son compte sera validée.
      </h5>
    </div>
    -->
  </div>
</template>

<script>
import accounts from "@/utils/api/accounts";
import date from "@/utils/date";

export default {
  data() {
    return {
      users: null,
      onlyNotValidatedFilter: false,
      currentPage: 0,
      newUserForm: {
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        birth_date: null,
        primary_category_identifier: null,
        how_discovered: null
      }
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers: function() {
      accounts
        .getAll(this.$data.currentPage, this.$data.onlyNotValidatedFilter)
        .then(res => {
          this.$data.users = res.data;
        });
    },
    /*
    TEMPORARY DISABLED
    postNewUser: function() {
      const form = this.$data.newUserForm;

      // set correct date format
      const birthDate = date.formatBirthDate(this.$data.newUserForm.birth_date);

      // call API
      accounts
        .postNew(
          form.first_name,
          form.last_name,
          form.username,
          form.email,
          birthDate,
          form.primary_category_identifier,
          form.how_discovered
        )

        // then, add to all users array (to the beginning of array)
        // and reset form
        .then(res => {
          this.$data.users.unshift(res.data);
          this.$data.newUserForm = {
            first_name: null,
            last_name: null,
            username: null,
            email: null,
            birth_date: null,
            primary_category_identifier: null,
            how_discovered: null
          };
        })

        // error (due to invalid form, server error, etc...)
        .catch(() =>
          alert(
            "Une erreur est apparue lors de la création de l'utilisateur, veuillez vérifier le formulaire"
          )
        );
    },
     */
    getProfilePicture: accounts.getProfilePicture,
    dateTransform: date.format,
    changePagination(isLess) {
      // update page counter and call api
      if (isLess && this.$data.currentPage !== 0) {
        this.$data.currentPage--;
        this.fetchUsers();
      } else if (!isLess && this.$data.users.length > 99) {
        this.$data.currentPage++;
        this.fetchUsers();
      }
    },
    changeOnlyNotValidatedFilter() {
      this.$data.onlyNotValidatedFilter = !this.$data.onlyNotValidatedFilter;
      this.fetchUsers();
    }
  }
};
</script>
